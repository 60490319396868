import { type Option, Some, None } from "@thames/monads"

function getEnv(key: string): Option<string> {
  const value = import.meta.env[key]
  return value === undefined ? Some(value) : None
}

function parseSentry() {
  const dsn = getEnv("SENTRY_DSN")
  return dsn.map((dsn) => ({
    dsn,
    tracesSampleRate: getEnv("SENTRY_TRACES_SAMPLE_RATE")
      .map(parseFloat)
      .unwrapOr(1.0),
  }))
}

const supportedCompanies = ["KIOSK", "GREENSTER"]
type Company = (typeof supportedCompanies)[number]

function parseCompany(): Option<Company> {
  const company = getEnv("VITE_COMPANY")
  return company.match({
    some: (value) => {
      if (!supportedCompanies.includes(value as Company)) {
        return None
      }
      return Some(value as Company)
    },
    none: () => None,
  })
}

export const clientConfig = {
  company: parseCompany().unwrapOr("KIOSK"),
  sentry: parseSentry(),
}
